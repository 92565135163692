import { Socket } from 'socket.io-client';
import { Reaction } from './components/ReactionSelector';

const RTCConfig: RTCConfiguration = {
  iceServers: [
    { urls: 'stun:stun.l.google.com:19302' },
    {
      urls: 'turn:chat.vsimon.hu:3478',
      username: 'group-video-chat',
      credential: 'group-video-chat',
    },
    {
      urls: 'stun:stun.relay.metered.ca:80',
    },
    {
      urls: 'turn:a.relay.metered.ca:80',
      username: '6c075f83e7c2f2c53fcdbc4e',
      credential: 'K1rMNOdlbLN/Qri6',
    },
    {
      urls: 'turn:a.relay.metered.ca:80?transport=tcp',
      username: '6c075f83e7c2f2c53fcdbc4e',
      credential: 'K1rMNOdlbLN/Qri6',
    },
    {
      urls: 'turn:a.relay.metered.ca:443',
      username: '6c075f83e7c2f2c53fcdbc4e',
      credential: 'K1rMNOdlbLN/Qri6',
    },
    {
      urls: 'turn:a.relay.metered.ca:443?transport=tcp',
      username: '6c075f83e7c2f2c53fcdbc4e',
      credential: 'K1rMNOdlbLN/Qri6',
    },
  ],
};

class RtcClient {
  connection: RTCPeerConnection;
  socket: Socket;
  makingOffer = false;
  candidateBuffer: RTCIceCandidate[] = [];

  constructor(socket: Socket) {
    this.connection = new RTCPeerConnection(RTCConfig);
    this.socket = socket;

    this.connection.onnegotiationneeded = () => this.onNegotiationNeeded();
    this.connection.onicecandidate = (e) => this.onIceCandidate(e.candidate);
    this.connection.onconnectionstatechange = () =>
      this.onConnectionStateChange();

    this.socket.on('offer', (e) => this.onOfferReceived(e));
    this.socket.on('answer', (e) => this.onAnswerReceived(e));
    this.socket.on('icecandidate', (e) => this.onIceCandidateReceived(e));
  }

  private async sendOffer() {
    try {
      this.makingOffer = true;
      await this.connection.setLocalDescription();
      this.socket.emit('offer', this.connection.localDescription);
      console.log('Sending offer');
    } catch (err) {
      console.error('Send offer error', err);
    } finally {
      this.makingOffer = false;
    }
  }

  private async onNegotiationNeeded() {
    await this.sendOffer();
  }

  private onIceCandidate(iceCandidate: RTCIceCandidate | null) {
    if (iceCandidate && iceCandidate.candidate !== '') {
      this.socket.emit('icecandidate', iceCandidate);
      console.log('Sending candidate', iceCandidate);
    }
  }

  private onConnectionStateChange() {
    console.log('Connection state: ', this.connection.connectionState);
  }

  private async onOfferReceived(offer: RTCSessionDescriptionInit) {
    const offerCollision =
      this.makingOffer || this.connection.signalingState !== 'stable';
    if (offerCollision) return;

    await this.connection.setRemoteDescription(offer);
    const answer = await this.connection.createAnswer();
    // answer.sdp = answer.sdp?.replace(
    //   'useinbandfec=1',
    //   'useinbandfec=1; stereo=1; maxaveragebitrate=510000',
    // );
    await this.connection.setLocalDescription(answer);
    this.socket.emit('answer', answer);
    console.log('Sending answer', answer);

    while (this.candidateBuffer.length) {
      const candidate = this.candidateBuffer.shift();
      if (candidate) {
        try {
          await this.connection.addIceCandidate(candidate);
        } catch (err) {
          console.error('Add ice candidate error', err, candidate);
        }
      }
    }
  }

  private async onAnswerReceived(answer: RTCSessionDescriptionInit) {
    await this.connection.setRemoteDescription(answer);
    console.log('Answer received', answer);
  }

  private async onIceCandidateReceived(candidate: RTCIceCandidate) {
    try {
      if (this.connection.remoteDescription) {
        await this.connection.addIceCandidate(candidate);
      } else {
        this.candidateBuffer.push(candidate);
      }
      console.log('Candidate received', candidate);
    } catch (err) {
      console.error('Candidate received error', err, candidate);
    }
  }

  sendReaction(reaction: Reaction) {
    this.socket.emit('reaction', reaction);
  }

  sendMessage(message: string) {
    this.socket.emit('message', message);
  }
}

export default RtcClient;
