/* eslint-disable react-hooks/exhaustive-deps */
import {
  Button,
  Container,
  createStyles,
  Divider,
  Group,
  Input,
  PinInput,
  rem,
  Stack,
  Text,
  Transition,
  useMantineTheme,
} from '@mantine/core';
import { useState } from 'react';
import { TbKey, TbLogin, TbVideoPlus } from 'react-icons/tb';
import { useNavigate } from 'react-router-dom';
import { useLazyCheckRoomQuery, useLazyCreateRoomQuery } from '../api/roomApi';
import Layout from './Layout';
import { useSelector } from 'react-redux';
import { selectUser } from '../state/userSlice';
import { notifications } from '@mantine/notifications';

const useStyles = createStyles((theme) => ({
  title: {
    fontSize: rem(46),
    fontWeight: 900,
    lineHeight: 1.2,
    margin: 0,
    padding: 0,
    marginTop: theme.spacing.xl,
    color: theme.white,

    [theme.fn.smallerThan('xs')]: {
      fontSize: rem(36),
    },
  },

  description: {
    fontSize: rem(20),
    marginTop: theme.spacing.xl,

    [theme.fn.smallerThan('xs')]: {
      fontSize: rem(18),
    },
  },
}));

function HomePage() {
  const navigate = useNavigate();
  const theme = useMantineTheme();
  const { classes } = useStyles();

  const [pin, setPin] = useState('');
  const [error, setError] = useState('');
  const user = useSelector(selectUser);

  const [createRoom, createResponse] = useLazyCreateRoomQuery();
  const [checkRoom, checkResponse] = useLazyCheckRoomQuery();

  function handlePinChange(s: string) {
    setPin(s);
    setError('');
  }

  function joinRoom(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();
    if (pin.length !== 6) {
      setError('Room id must be 6 digits long');
      return;
    }
    checkRoom(pin)
      .unwrap()
      .then((exists) => {
        if (exists) {
          navigate(`/room/${pin}`);
        } else {
          setError('Room does not exist');
        }
      })
      .catch(() => {
        setError('Something went wrong');
      });
  }

  function createRoomHandler() {
    createRoom(user.token)
      .unwrap()
      .then((roomId) => {
        navigate(`/room/${roomId}`);
      })
      .catch((err) => {
        if (err.status === 401 || err.status === 400) {
          notifications.show({
            title: 'Authentication needed',
            message: 'Please login to create a room',
            color: 'yellow',
            icon: <TbLogin size={20} />,
          });
          navigate('/login');
        }
      });
  }

  function onPinPaste(e: React.ClipboardEvent<HTMLInputElement>) {
    e.preventDefault();
    const value = e.clipboardData
      .getData('text/plain')
      .trim()
      .replaceAll(/\D/g, '')
      .slice(0, 6);
    setPin(value);
  }

  return (
    <Layout>
      <Container
        size={700}
        sx={{
          textAlign: 'center',
        }}
      >
        <h1 className={classes.title}>
          One easy place for{' '}
          <Text
            component="span"
            variant="gradient"
            gradient={{ from: 'blue', to: 'cyan' }}
            inherit
          >
            video calls and chat
          </Text>{' '}
          with your friends.
        </h1>
        <Text color="dimmed" className={classes.description}>
          You can create a room and invite your friends to join the room. You
          can also join a room by entering the room id.
        </Text>
        <Stack mt={52} spacing={theme.spacing.xl}>
          <Button
            gradient={{ from: 'indigo', to: 'cyan' }}
            size="md"
            leftIcon={<TbVideoPlus size={22} />}
            loading={createResponse.isLoading}
            onClick={createRoomHandler}
          >
            Create a room
          </Button>
          <Divider label="OR" labelPosition="center" />
          <form onSubmit={joinRoom}>
            <Stack>
              <Group position="center" sx={{ position: 'relative' }}>
                <PinInput
                  size="lg"
                  length={6}
                  type="number"
                  value={pin}
                  onChange={handlePinChange}
                  error={error !== ''}
                  disabled={checkResponse.isLoading}
                  onPaste={onPinPaste}
                />
              </Group>
              <Button
                type="submit"
                leftIcon={<TbKey size={22} />}
                size="md"
                loading={checkResponse.isLoading}
              >
                Join
              </Button>
              <Transition
                mounted={error !== ''}
                transition="fade"
                timingFunction="ease"
              >
                {(styles) => <Input.Error style={styles}>{error}</Input.Error>}
              </Transition>
            </Stack>
          </form>
        </Stack>
      </Container>
    </Layout>
  );
}

export default HomePage;
