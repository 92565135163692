/* eslint-disable react-hooks/exhaustive-deps */
import {
  AspectRatio,
  Button,
  Center,
  Container,
  Grid,
  Group,
  Loader,
  Paper,
  Select,
  Stack,
  Text,
  TextInput,
  Title,
  Transition,
} from '@mantine/core';
import { useEffect, useState, useMemo, useRef } from 'react';
import ToggleButton from './ToggleButton';
import {
  TbMicrophone,
  TbMicrophoneOff,
  TbVideo,
  TbVideoOff,
  TbUserCircle,
  TbX,
  TbLogin,
} from 'react-icons/tb';
import { showNotification } from '@mantine/notifications';
import { useSelector } from 'react-redux/es/exports';
import {
  selectUserMedia,
  setAudioDeviceId,
  setAudioEnabled,
  setVideoDeviceId,
  setVideoEnabled,
  toggleAudio,
  toggleVideo,
} from '../state/userMediaSlice';
import { useDispatch } from 'react-redux/es/hooks/useDispatch';
import { selectUser, setUsername } from '../state/userSlice';
import { useCheckRoomQuery } from '../api/roomApi';
import { useParams } from 'react-router-dom';
import useMuter from '../hooks/useMuter';

interface JoinPageProps {
  setReady: (ready: boolean) => void;
}

function JoinPage({ setReady }: JoinPageProps) {
  const { id: roomId } = useParams();

  const dispatch = useDispatch();
  const userMedia = useSelector(selectUserMedia);
  const user = useSelector(selectUser);

  const [name, setName] = useState(user.name);
  const [localStream, setLocalStream] = useState<MediaStream>(
    new MediaStream(),
  );
  const [videoDevices, setVideoDevices] = useState<MediaDeviceInfo[]>([]);
  const [audioDevices, setAudioDevices] = useState<MediaDeviceInfo[]>([]);
  const localStreamRef = useRef(localStream);
  const hasVideo = localStream.getVideoTracks().length > 0;

  useMuter(localStream);

  const {
    data: roomExists,
    isLoading,
    isSuccess,
    isError,
  } = useCheckRoomQuery(roomId!);

  const loadUserMedia = async () => {
    stopLocalStream();
    try {
      const stream = await navigator.mediaDevices.getUserMedia({
        audio: userMedia.audioDeviceId
          ? { deviceId: userMedia.audioDeviceId }
          : true,
        video: userMedia.videoDeviceId
          ? { deviceId: userMedia.videoDeviceId }
          : { facingMode: 'user' },
      });

      dispatch(setAudioEnabled(true));
      dispatch(setVideoEnabled(true));
      setLocalStream(stream);
      localStreamRef.current = stream;
    } catch (err) {
      showNotification({
        title: 'Permission denied',
        message: 'You need to allow camera and microphone access',
        color: 'red',
        icon: <TbX size={24} />,
      });
      dispatch(setAudioEnabled(false));
      dispatch(setVideoEnabled(false));
    }
  };

  const loadMediaDevices = async () => {
    const devices = await navigator.mediaDevices.enumerateDevices();
    const videoDevices = devices.filter(
      (device) => device.kind === 'videoinput',
    );
    const audioDevices = devices.filter(
      (device) => device.kind === 'audioinput',
    );

    setVideoDevices(videoDevices);
    setAudioDevices(audioDevices);
  };

  const init = async () => {
    await loadUserMedia();
    await loadMediaDevices();

    if (userMedia.videoDeviceId === '' && videoDevices.length > 0) {
      const defaultCamera = videoDevices[0];
      console.log('default camera', defaultCamera);
      dispatch(setVideoDeviceId(defaultCamera.deviceId));
    }
    if (userMedia.audioDeviceId === '' && audioDevices.length > 0) {
      const defaultMic = audioDevices[0];
      console.log('default mic', defaultMic);
      dispatch(setVideoDeviceId(defaultMic.deviceId));
    }
  };

  useEffect(() => {
    init();

    return () => {
      stopLocalStream();
    };
  }, []);

  useEffect(() => {
    loadUserMedia();
  }, [userMedia.videoDeviceId, userMedia.audioDeviceId]);

  function stopLocalStream() {
    localStreamRef.current.getTracks().forEach((track) => track.stop());
  }

  const handleAudioToggle = (turnedOn: boolean) => {
    dispatch(toggleAudio());
  };

  const handleVideoToggle = (turnedOn: boolean) => {
    dispatch(toggleVideo());
  };

  const previewVideo = useMemo(
    () => (
      <video
        autoPlay
        muted
        ref={(el) => {
          if (el && localStream) {
            el.srcObject = localStream;
          }
        }}
        style={{
          objectFit: 'cover',
          height: '100%',
          width: '100%',
          borderRadius: 8,
          transform: 'scaleX(-1)',
          WebkitTransform: 'scaleX(-1)',
        }}
      />
    ),
    [localStream],
  );

  const joinRoom = () => {
    if (!user.token) {
      dispatch(setUsername(name));
    }
    setReady(true);
  };

  return (
    <Container size="sm" h="100%">
      <Stack h="100%" justify="center" spacing="xs">
        <Stack spacing="sm">
          <AspectRatio ratio={16 / 9}>
            {userMedia.videoEnabled && hasVideo ? (
              previewVideo
            ) : (
              <Paper w="100%" h="100%">
                <Center>
                  <Stack align="center">
                    <TbVideoOff size={64} />
                    <Text size="xl">No video</Text>
                  </Stack>
                </Center>
              </Paper>
            )}
          </AspectRatio>
          <Grid justify="space-between" align="center" gutter="xs">
            <Grid.Col span={12} xs="content" orderXs={2}>
              <Group position="center">
                <ToggleButton
                  onIcon={<TbMicrophone size={24} />}
                  offIcon={<TbMicrophoneOff size={24} />}
                  onClick={handleAudioToggle}
                  value={userMedia.audioEnabled}
                />
                <ToggleButton
                  onIcon={<TbVideo size={24} />}
                  offIcon={<TbVideoOff size={24} />}
                  onClick={handleVideoToggle}
                  value={userMedia.videoEnabled}
                />
              </Group>
            </Grid.Col>
            <Grid.Col span={12} xs="auto" orderXs={1}>
              <Select
                icon={<TbMicrophone size={20} />}
                placeholder="Default microphone"
                size="md"
                data={audioDevices.map((d) => ({
                  label: d.label,
                  value: d.deviceId,
                }))}
                value={userMedia.audioDeviceId}
                onChange={(v) => {
                  if (v) dispatch(setAudioDeviceId(v));
                }}
                onDropdownOpen={() => loadMediaDevices()}
              />
            </Grid.Col>
            <Grid.Col span={12} xs="auto" orderXs={3}>
              <Select
                icon={<TbVideo size={20} />}
                placeholder="Default camera"
                size="md"
                data={videoDevices.map((d) => ({
                  label: d.label,
                  value: d.deviceId,
                }))}
                value={userMedia.videoDeviceId}
                onChange={(v) => {
                  if (v) dispatch(setVideoDeviceId(v));
                }}
                onDropdownOpen={() => loadMediaDevices()}
              />
            </Grid.Col>
          </Grid>
        </Stack>
        <Transition mounted={isLoading} transition="pop" timingFunction="ease">
          {(styles) => (
            <Center style={styles} mt="lg">
              <Loader />
            </Center>
          )}
        </Transition>
        <Transition
          mounted={isSuccess && roomExists}
          transition="pop"
          timingFunction="ease"
        >
          {(styles) => (
            <form onSubmit={joinRoom} style={styles}>
              <Stack>
                {!user.token && (
                  <TextInput
                    placeholder="Your name"
                    onChange={(e) => setName(e.target.value)}
                    value={name}
                    icon={<TbUserCircle size={24} />}
                    size="md"
                    maxLength={30}
                    autoFocus
                  />
                )}
                <Button
                  disabled={name.length === 0 && user.token === ''}
                  size="md"
                  type="submit"
                  leftIcon={<TbLogin size={20} />}
                  mt="lg"
                >
                  {user.token ? `Join as ${user.name}` : `Join`}
                </Button>
              </Stack>
            </form>
          )}
        </Transition>
        <Transition
          mounted={isSuccess && !roomExists}
          transition="pop"
          timingFunction="ease"
        >
          {(styles) => (
            <Center style={styles}>
              <Stack align="center" spacing={0} mt="lg">
                <Title order={3}>Room not found</Title>
                <Text c="dimmed" size="lg">
                  Please check the room ID
                </Text>
              </Stack>
            </Center>
          )}
        </Transition>
        <Transition mounted={isError} transition="pop" timingFunction="ease">
          {(styles) => (
            <Center style={styles}>
              <Stack align="center" spacing={0} mt="lg">
                <Title order={3}>Network error</Title>
                <Text c="dimmed" size="lg">
                  Please check your internet connection
                </Text>
              </Stack>
            </Center>
          )}
        </Transition>
      </Stack>
    </Container>
  );
}

export default JoinPage;
